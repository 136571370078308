import React from 'react';
import Menu from "../components/Menu";
import Header from "../components/Header";
import BrandMain from "../components/BrandMain";
import Footer from "../components/Footer";
const Home = () => {
    return (
        <div id="bodyy">
            <Menu/>
            <Header/>
            <BrandMain/>
            <Footer/>
        </div>
    );
};

export default Home;