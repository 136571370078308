import React from 'react';
import "./sass/main.scss"
import Home from "./Pages/Home";
import {BrowserRouter,Switch,Route} from "react-router-dom";
import BackToTop from "react-back-to-top-button";

const App = () => {
    const style = {
        borderRadius:'50%',
        width:'50px',
        height:'50px',
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
        backgroundColor:"#cb11ab",
        color:"#ffffff",
    };
    return (
        <>
            <BrowserRouter>
                <Switch>
                    <Route path="/" exact component={Home}/>
                </Switch>
            </BrowserRouter>
            <BackToTop
                // showOnScrollUp
                showAt={10}
                speed={5000}
                // easing="easeInOutQuint"
            >
                <span><button style={style} className="btn p-3"><i className="fas fa-arrow-up"/></button></span>
            </BackToTop>
        </>
    );
};

export default App;