const carusel=[
    {
        id:1,
        img:"https://images.wbstatic.net/bners1/892994566.jpg "
    },
    {
        id:2,
        img:"https://images.wbstatic.net/bners1/big_oysho_0609.jpg "
    },
    {
        id:3,
        img:"https://images.wbstatic.net/bners1/big_delivery_1609.jpg "
    },
    {
        id:4,
        img:"https://images.wbstatic.net/bners1/big_books_1609.jpg "
    },
    {
        id:5,
        img:"https://images.wbstatic.net/bners1/big_toys_1609.jpg "
    },
    {
        id:6,
        img:"https://images.wbstatic.net/bners1/big_holodilnik_1609_1.jpg "
    },
    {
        id:7,
        img:"https://images.wbstatic.net/bners1/big_zagran_goods_1609.jpg "
    },
    {
        id:8,
        img:"https://images.wbstatic.net/bners1/big_kredit_1609.jpg "
    },
    {
        id:9,
        img:" https://images.wbstatic.net/bners1/big_food_1609_1.jpg"
    },
    {
        id:10,
        img: "https://images.wbstatic.net/bners1/big_midseason_1609_1.jpg"
    },
    {
        id:11,
        img: "https://images.wbstatic.net/bners1/big_midseason_sale_1609_2.jpg"
    },
    {
        id:12,
        img: "https://images.wbstatic.net/bners1/big_electro_1609.jpg"
    }
]
export default carusel