const productSecond=[
    {
       id:1,
       img:"https://images.wbstatic.net/small/new/6790000/6792770.jpg",
       price:"3 490 ₽",
       lastPrice:"1 500 ₽",
       type:"Philips / Фен BHD169/00"
    },
    {
        id:2,
        img:"https://images.wbstatic.net/small/new/10890000/10899304.jpg",
        price:"3 537 ₽",
        lastPrice:"1 108 ₽",
        type:"MILEY / Швабра"
    },
    {
        id:3,
        img:"https://images.wbstatic.net/small/new/10970000/10978789.jpg",
        price:"1 100 ₽",
        lastPrice:"519 ₽",
        type:"IRIS / Тюль"
    },
    {
        id:4,
        img:"https://images.wbstatic.net/small/new/8570000/8576622.jpg",
        price:"3 990 ₽",
        lastPrice:"1 955 ₽",
        type:"Мама и малыш / Подушка 150х35 см / холлофайбер"
    },
    {
        id:5,
        img:"https://images.wbstatic.net/small/new/7360000/7365023.jpg",
        price:"2 594 ₽",
        lastPrice:"6 000 ₽",
        type:"Major Fabric / Джинсы"
    },
    {
        id:6,
        img:"https://images.wbstatic.net/small/new/10960000/10964684.jpg",
        price:"4 999 ₽",
        lastPrice:"2 999 ₽",
        type:"CROCS / Сабо"
    },
    {
        id:7,
        img:"https://images.wbstatic.net/small/new/8550000/8558201.jpg",
        price:"2 200 ₽",
        lastPrice:"475 ₽",
        type:"AxWild / Коврик для раскатки теста"
    },
    {
        id:8,
        img:"https://images.wbstatic.net/small/new/520000/520058.jpg",
        price:"2 599 ₽",
        lastPrice:"",
        type:"CROCS / Сандалии"
    },
    {
        id:9,
        img:"https://images.wbstatic.net/small/new/12800000/12801485.jpg",
        price:"667 ₽",
        lastPrice:"350 ₽",
        type:"UZcotton / Футболка"
    },
    {
        id:10,
        img:"https://images.wbstatic.net/small/new/8710000/8710325.jpg",
        price:"1 338 ₽",
        lastPrice:"1 998 ₽",
        type:"YokoSun / Подгузники детские 9-14 кг / 44 шт."
    },
    {
        id:11,
        img:"https://images.wbstatic.net/small/new/9780000/9784640.jpg",
        price:"3 258 ₽",
        lastPrice:"2 899 ₽",
        type:"Animal / Добавка для суставов и связок в капсулах"
    },
    {
        id:12,
        img:"https://images.wbstatic.net/small/new/12620000/12622014.jpg",
        price:"572 ₽",
        lastPrice:"120 ₽",
        type:"МедЗащита / Маска косметическая"
    }
]
export default productSecond