const brand=[
    {
        id:1,
        title:"Женщинам",
        imgOne:"https://images.wbstatic.net/brands/small/new/1418.jpg",
        imgSecond:"https://images.wbstatic.net/brands/small/new/1304.jpg",
        imgThree:"https://images.wbstatic.net/brands/small/new/55857.jpg",
        imgFour:"https://images.wbstatic.net/brands/small/new/29379.jpg",
        imgFive:"https://images.wbstatic.net/brands/small/new/4841.jpg"
    },
    {
        id:2,
        title:"Мужчинам",
        imgOne:"https://images.wbstatic.net/brands/small/new/14278.jpg",
        imgSecond:"https://images.wbstatic.net/brands/small/new/14280.jpg",
        imgThree:"https://images.wbstatic.net/brands/small/new/37954.jpg",
        imgFour:"https://images.wbstatic.net/brands/small/new/14126.jpg",
        imgFive:"https://images.wbstatic.net/brands/small/new/13991.jpg"
    },
    {
        id:3,
        title:"Детям",
        imgOne:"https://images.wbstatic.net/brands/small/new/13991.jpg",
        imgSecond:"https://images.wbstatic.net/brands/small/new/561.jpg",
        imgThree:"https://images.wbstatic.net/brands/small/new/362.jpg",
        imgFour:"https://images.wbstatic.net/brands/small/new/14426.jpg",
        imgFive:"https://images.wbstatic.net/brands/small/new/669.jpg"
    },
    {
        id:4,
        title:"Обувь",
        imgOne:"https://images.wbstatic.net/brands/small/new/75084.jpg",
        imgSecond:"https://images.wbstatic.net/brands/small/new/14264.jpg",
        imgThree:"https://images.wbstatic.net/brands/small/new/16694.jpg",
        imgFour:"https://images.wbstatic.net/brands/small/new/561.jpg",
        imgFive:"https://images.wbstatic.net/brands/small/new/503.jpg"
    },
    {
        id:5,
        title:"Аксессуары",
        imgOne:"https://images.wbstatic.net/brands/small/new/461.jpg",
        imgSecond:"https://images.wbstatic.net/brands/small/new/14347.jpg",
        imgThree:"https://images.wbstatic.net/brands/small/new/38796.jpg",
        imgFour:"https://images.wbstatic.net/brands/small/new/4733.jpg",
        imgFive:"https://images.wbstatic.net/brands/small/new/3244.jpg"
    },
    {
        id:6,
        title:"PREMIUM",
        imgOne:"https://images.wbstatic.net/brands/small/new/461.jpg",
        imgSecond:"https://images.wbstatic.net/brands/small/new/3244.jpg",
        imgThree:"https://images.wbstatic.net/brands/small/new/30253.jpg",
        imgFour:"https://images.wbstatic.net/brands/small/new/502.jpg",
        imgFive:"https://images.wbstatic.net/brands/small/new/990.jpg"
    },
    {
        id:7,
        title:"Спорт",
        imgOne:"https://images.wbstatic.net/brands/small/new/1031.jpg",
        imgSecond:"https://images.wbstatic.net/brands/small/new/561.jpg",
        imgThree:"https://images.wbstatic.net/brands/small/new/19229.jpg",
        imgFour:"https://images.wbstatic.net/brands/small/new/1199.jpg",
        imgFive:"https://images.wbstatic.net/brands/small/new/748.jpg"
    },
    {
        id:8,
        title:"Игрушки",
        imgOne:"https://images.wbstatic.net/brands/small/new/1418.jpg",
        imgSecond:"https://images.wbstatic.net/brands/small/new/1304.jpg",
        imgThree:"https://images.wbstatic.net/brands/small/new/55857.jpg",
        imgFour:"https://images.wbstatic.net/brands/small/new/29379.jpg",
        imgFive:"https://images.wbstatic.net/brands/small/new/4841.jpg"
    },
    {
        id:9,
        title:"Красота",
        imgOne:"https://images.wbstatic.net/brands/small/new/14278.jpg",
        imgSecond:"https://images.wbstatic.net/brands/small/new/14280.jpg",
        imgThree:"https://images.wbstatic.net/brands/small/new/37954.jpg",
        imgFour:"https://images.wbstatic.net/brands/small/new/14279.jpg",
        imgFive:"https://images.wbstatic.net/brands/small/new/21981.jpg"
    },
    {
        id:10,
        title:"Электроника",
        imgOne:"https://images.wbstatic.net/brands/small/new/14278.jpg",
        imgSecond:"https://images.wbstatic.net/brands/small/new/15772.jpg",
        imgThree:"https://images.wbstatic.net/brands/small/new/16049.jpg",
        imgFour:"https://images.wbstatic.net/brands/small/new/17658.jpg",
        imgFive:"https://images.wbstatic.net/brands/small/new/16002.jpg"
    },
    {
        id:11,
        title:"Ювелирные изделия",
        imgOne:"https://images.wbstatic.net/brands/small/new/14603.jpg",
        imgSecond:"https://images.wbstatic.net/brands/small/new/163635.jpg",
        imgThree:"https://images.wbstatic.net/brands/small/new/24629.jpg",
        imgFour:"https://images.wbstatic.net/brands/small/new/28415.jpg",
        imgFive:"https://images.wbstatic.net/brands/small/new/32688.jpg"
    },
    {
        id:12,
        title:"Книги",
        imgOne:"https://images.wbstatic.net/brands/small/new/14481.jpg",
        imgSecond:"https://images.wbstatic.net/brands/small/new/16342.jpg",
        imgThree:"https://images.wbstatic.net/brands/small/new/15971.jpg",
        imgFour:"https://images.wbstatic.net/brands/small/new/16299.jpg",
        imgFive:"https://images.wbstatic.net/brands/small/new/61143.jpg"
    },
    {
        id:13,
        title:"Дом",
        imgOne:"https://images.wbstatic.net/brands/small/new/17716.jpg",
        imgSecond:"https://images.wbstatic.net/brands/small/new/4451.jpg",
        imgThree:"https://images.wbstatic.net/brands/small/new/3604.jpg",
        imgFour:"https://images.wbstatic.net/brands/small/new/34807.jpg",
        imgFive:"https://images.wbstatic.net/brands/small/new/66988.jpg"
    },
    {
        id:14,
        title:"Продукты",
        imgOne:"https://images.wbstatic.net/brands/small/new/27608.jpg",
        imgSecond:"https://images.wbstatic.net/brands/small/new/27853.jpg",
        imgThree:"https://images.wbstatic.net/brands/small/new/26156.jpg",
        imgFour:"https://images.wbstatic.net/brands/small/new/25590.jpg",
        imgFive:"https://images.wbstatic.net/brands/small/new/26155.jpg"
    },
    {
        id:15,
        title:"Зоотовары",
        imgOne:"https://images.wbstatic.net/brands/small/new/34253.jpg",
        imgSecond:"https://images.wbstatic.net/brands/small/new/51830.jpg",
        imgThree:"https://images.wbstatic.net/brands/small/new/45029.jpg",
        imgFour:"https://images.wbstatic.net/brands/small/new/51528.jpg",
        imgFive:"https://images.wbstatic.net/brands/small/new/34256.jpg"
    },
    {
        id:16,
        title:"Здоровье",
        imgOne:"https://images.wbstatic.net/brands/small/new/16002.jpg",
        imgSecond:"https://images.wbstatic.net/brands/small/new/16012.jpg",
        imgThree:"https://images.wbstatic.net/brands/small/new/32902.jpg",
        imgFour:"https://images.wbstatic.net/brands/small/new/16096.jpg",
        imgFive:"https://images.wbstatic.net/brands/small/new/33970.jpg"
    },
    {
        id:17,
        title:"Спортивное питание и косметика",
        imgOne:"https://images.wbstatic.net/brands/small/new/98339.jpg",
        imgSecond:"https://images.wbstatic.net/brands/small/new/33025.jpg",
        imgThree:"https://images.wbstatic.net/brands/small/new/19859.jpg",
        imgFour:"https://images.wbstatic.net/brands/small/new/41560.jpg",
        imgFive:"https://images.wbstatic.net/brands/small/new/48475.jpg"
    }
]
export default brand
