import React, {useState} from 'react';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import brand from "./brand";
import {Link} from "react-router-dom";

const BrandMain = () => {
    const [brands, setBrands] = useState(brand)

    const responsive = {
        superLargeDesktop: {
            breakpoint: {max: 4000, min: 3000},
            items: 13
        },
        desktop: {
            breakpoint: {max: 3000, min: 1024},
            items: 10
        },
        tablet: {
            breakpoint: {max: 1024, min: 464},
            items: 3
        },
        mobile: {
            breakpoint: {max: 464, min: 0},
            items: 2
        }
    };
    return (
        <>
            <section className="brand-main">
                <div className="d-flex justify-content-start align-items-center top-brand">
                    <h1 className="mb-0">Популярные бренды</h1>
                    <Link>Смотреть все</Link>
                </div>
                <Carousel responsive={responsive}>
                    {brands.map((item, index) => {
                        const {id, title, imgOne, imgSecond, imgThree, imgFour, imgFive} = item

                        return (
                            <div className='col-2a'>
                                <button className="btn"><Link>{title}</Link></button>
                                <ul>
                                    <li><a href="#"><img src={imgOne} alt=""/></a></li>
                                    <li><a href="#"><img src={imgSecond} alt=""/></a></li>
                                    <li><a href="#"><img src={imgThree} alt=""/></a></li>
                                    <li><a href="#"><img src={imgFour} alt=""/></a></li>
                                    <li><a href="#"><img src={imgFive} alt=""/></a></li>
                                </ul>
                            </div>
                        )
                    })}
                </Carousel>
                <div className="row">
                    <h6>Широкий ассортимент и высокое качество</h6>
                    <p>Интернет-магазин Wildberries – это доступные цены, широкий, регулярно обновляемый ассортимент. В
                        онлайн-каталоге Wildberries представлено около 35 000 ведущих брендов женской, мужской и детской
                        одежды и обуви. Покупателям предлагается электроника, книжная продукция, детские товары. В
                        интернет-магазине можно приобрести продукцию для дома, продукты питания, товары для красоты,
                        ювелирные изделия, игрушки. Для удобства пользования онлайн-каталог поделен на разделы, все
                        товары можно сортировать по ряду критериев: цена, материал изготовления, сезонность, бренд.

                        Выгодный шопинг

                        Интернет-магазин Wildberries регулярно проводит масштабные распродажи. В рамках таких акций
                        предоставляются большие скидки (до 95%) на одежду, обувь, детские товары. Условия распродаж
                        .</p>
                    <Link>Читать далее</Link>
                </div>
            </section>
        </>
    );
};

export default BrandMain;