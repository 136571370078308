import React,{useState} from 'react';
import {Link} from "react-router-dom";
import {Button, Input, InputGroup, InputGroupAddon,Tooltip } from "reactstrap";
import noneMenu from "./none-menu";
const Menu = () => {

    const [tooltipOpen, setTooltipOpen] = useState(false);
    const [tooltipOpenOne, setTooltipOpenOne] = useState(false);
    const toggle = () => setTooltipOpen(!tooltipOpen);
    const toggleOne = () => setTooltipOpenOne(!tooltipOpenOne);
    const [style,setStyle]=useState('translateX(-100%)')
    const [styleOne,setStyleOne]=useState(0)
    const[noneMenus,setNoneMenus]=useState(noneMenu)
    return (
        <>
            <section className="menu">
                <div className="container-fluid">
                    <div className="top">
                        <div className="d-flex justify-content-start align-items-center">
                            <div className="country">
                                <h6>Ru</h6>
                                <img
                                    src="https://img5.goodfon.ru/wallpaper/big/6/5f/russian-flag-russian-flag-russia-flag-of-russia.jpg" alt=""/>
                            </div>
                            <div className="capital">
                                <i className="fas fa-map-marker-alt"/>
                                <h6>Москва</h6>
                            </div>
                            <Link>Бесплатная доставка</Link>
                            <button className="btn btn-success top-first-button">Продавайте на Wildberries</button>
                        </div>
                       <div>
                           <button className="btn btn-success top-second-button"><p className="mb-0">Сообщить о проблеме</p> <i
                               className="far fa-comment-dots"/></button>
                       </div>
                    </div>
                    <div className="menu-bar">
                        <div className="d-flex justify-content-start align-items-center">
                            <div>
                                <button onClick={()=>{
                                    setStyle('translateX(0)')
                                    setStyleOne(1)
                                }} className="btn menu-bars"><i className="fas fa-bars"/></button>
                            </div>
                            <div style={{transform:style}} className="none-menu d-flex">
                                <ul>
                                    {noneMenus.map((item,index)=>{
                                        const {id,list}=item
                                        return(
                                            <li>
                                                <Link><i className="fas fa-female"/>{list}</Link>
                                            </li>
                                        )
                                    })}

                                </ul>

                            </div>

                            <buttonn style={{opacity:styleOne}} onClick={()=>{
                                setStyle('translateX(-100%)')
                                setStyleOne(0)
                            }}  className="btnn btn-warningg"><i className="fas fa-times"/></buttonn>
                            <div>
                                <Link to="/"><img src="https://static.wbstatic.net/i/header/logo-v1.svg" alt=""/></Link>
                            </div>
                            <div>
                                <InputGroup>
                                    <Input placeholder="Я ищу" />
                                    <InputGroupAddon addonType="append">
                                        <Button color="success"><i className="fas fa-search"/></Button>
                                        <Button id="TooltipExample"  type="file" color="success">
                                            <i className="fas fa-camera"/></Button>
                                        <Tooltip placement="bottom" isOpen={tooltipOpen} target="TooltipExample" toggle={toggle}>
                                            Поиск по фото
                                        </Tooltip>
                                    </InputGroupAddon>
                                </InputGroup>
                            </div>
                        </div>
                        <div className="d-flex justify-content-start align-items-center menu-end-div">
                               <Link>
                                   <div id="TooltipExampleOne" className="menu-bar-info">
                                       <i className="fas fa-map-marker-alt"/>
                                       <h6>Адреса</h6>
                                   </div>
                                   <Tooltip placement="bottom" isOpen={tooltipOpenOne} target="TooltipExampleOne" toggle={toggleOne}>
                                       <h6>20918  пунктов выдачи</h6>
                                       <h6>322  постамата</h6>
                                   </Tooltip>
                               </Link>

                                <Link>
                                    <div className="menu-bar-info">
                                        <i className="fas fa-user"/>
                                        <h6>Войти</h6>
                                    </div>
                                </Link>
                                <Link>
                                    <div className="menu-bar-info">
                                        <i className="fas fa-shopping-cart"/>
                                        <h6>Корзина</h6>
                                    </div>
                                </Link>
                            </div>
                    </div>
                    <div className="input-none d-nonee">
                        <InputGroup>
                            <Input placeholder="Я ищу" />
                            <InputGroupAddon addonType="append">
                                <Button color="success"><i className="fas fa-search"/></Button>
                                <Button id="TooltipExample"  type="file" color="success">
                                    <i className="fas fa-camera"/></Button>
                                <Tooltip placement="bottom" isOpen={tooltipOpen} target="TooltipExample" toggle={toggle}>
                                    Поиск по фото
                                </Tooltip>
                            </InputGroupAddon>
                        </InputGroup>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Menu;