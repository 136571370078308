const cardImg=[
    {
        id:1,
        img:"https://images.wbstatic.net/bners1/2_Loreal_desk.jpg"
    },
    {
        id:2,
        img:"https://images.wbstatic.net/bners1/2_Asics_app.jpg"
    },
    {
        id:3,
        img:"https://images.wbstatic.net/bners1/sokolov_desk.jpg"
    },
    {
        id:4,
        img:"https://images.wbstatic.net/bners1/2_Philips_desk.jpg"
    },
    {
        id:5,
        img:"https://images.wbstatic.net/bners1/Tefal_desk_839305.jpg"
    },
    {
        id:6,
        img:"https://images.wbstatic.net/bners1/Gigiena_desk_83930566.jpg"
    },
    {
        id:7,
        img:"https://images.wbstatic.net/bners1/shampuni_1009_desk_83934.jpg"
    },
    {
        id:8,
        img:"https://images.wbstatic.net/bners1/pampers_1009.jpg"
    }
]
export default cardImg