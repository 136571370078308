const productFirst=[
    {
        id:1,
        img:"https://images.wbstatic.net/small/new/4540000/4545690.jpg",
        price:"5 980 ₽",
        lastPrice:"5 580 ₽",
        type:"Philips / Электрическая зубная щетка Sonicare HealthyWhite HX6762/43 / звуковая"
    },
    {
        id:2,
        img:"https://images.wbstatic.net/small/new/8880000/8888430.jpg",
        price:"2 990 ₽",
        lastPrice:"2 490 ₽",
        type:"Honor / Фитнес-браслет Band 5"
    },
    {
        id:3,
        img:"https://images.wbstatic.net/small/new/13720000/13724854.jpg",
        price:"2 596 ₽",
        lastPrice:'2 396 ₽',
        type:"Raven / Худи "
    },
    {
        id:4,
        img:"https://images.wbstatic.net/small/new/11120000/11127342.jpg",
        price:"376 ₽",
        lastPrice:'326 ₽',
        type:"НАНОПЯТКИ / Косметический набор для ухода "
    },
    {
        id:5,
        img:"https://images.wbstatic.net/small/new/2140000/2147837.jpg",
        price:"2 041 ₽",
        lastPrice:'1 741 ₽',
        type:"GILLETTE / Кассета для бритв "
    },
    {
        id:6,
        img:"https://images.wbstatic.net/small/new/12250000/12255057.jpg",
        price:"157 ₽",
        lastPrice:'127 ₽',
        type:"МедТранс / Маска одноразовая "
    },
    {
        id:7,
        img:"https://images.wbstatic.net/small/new/13220000/13221317.jpg",
        price:"10 822 ₽",
        lastPrice:'10 422 ₽',
        type:"AmaroBaby / Автокресло детское 0/1/2/3 ( до 36 ) / 0-36 "
    },
    {
        id:8,
        img:"https://images.wbstatic.net/small/new/12920000/12920336.jpg",
        price:"3 818 ₽",
        lastPrice:'3 318 ₽',
        type:"ТМ Belezzaa / Костюм "
    },
    {
        id:9,
        img:"https://images.wbstatic.net/small/new/12400000/12403169.jpg",
        price:"170 990 ₽",
        lastPrice:'170 390 ₽',
        type:"Apple / Ноутбук 16 ГБ / 512 Гб / 13\" / 2560x1600 / Intel Iris Plus Graphics / macOS "
    },
    {
        id:10,
        img:"https://images.wbstatic.net/small/new/2140000/2147739.jpg",
        price:"2 245 ₽",
        lastPrice:'2 045 ₽',
        type:"GILLETTE / Кассета для бритв "
    },
    {
        id:11,
        img:"https://images.wbstatic.net/small/new/8270000/8279056.jpg",
        price:"339 ₽",
        lastPrice:'239 ₽',
        type:"Просто Здорово / Паста ореховая арахис / арахис жареный / Арахис без соли "
    },
    {
        id:12,
        img:"https://images.wbstatic.net/small/new/6790000/6792770.jpg",
        price:"1 500 ₽",
        lastPrice:'1 200 ₽',
        type:"Philips / Фен BHD169/00  "
    },

]
export default productFirst