const noneMenu=[
    {
        id:1,
        list:"Женщинам",
    },
    {
        id:2,
        list: "Мужчинам"
    },
    {
        id:3,
        list: "Детям"
    },
    {
        id:4,
        list: "Обувь"
    },
    {
        id:5,
        list: "Аксессуары"
    },
    {
        id:6,
        list: "Электроника"
    },
    {
        id:7,
        list: "Видеообзоры"
    },
    {
        id:8,
        list: "Авиабилеты"
    },
    {
        id:9,
        list: "Экспресс-доставка"
    },
    {
        id:10,
        list: "Premium"
    },
    {
        id:11,
        list: "Бытовая техника"
    },
    {
        id:12,
        list: "Книги"
    },
    {
        id:13,
        list: "Спорт"
    },
    {
        id:14,
        list: "Школа"
    },
    {
        id:15,
        list: "Красота"
    },
    {
        id:16,
        list: "Игрушки"
    },
    {
        id:17,
        list: "Продукты"
    },
    {
        id:18,
        list: "Сад и дача"
    },
    {
        id:19,
        list: "Зоотовары"
    },
    {
        id:20,
        list: "Канцтовары"
    },
    {
        id:21,
        list: "Здоровье"
    },
    {
        id:22,
        list: "Фармацевтика"
    },
    {
        id:23,
        list: "Для ремонта"
    },
    {
        id:24,
        list: "Дом"
    },
    {
        id:25,
        list: "Автотовары"
    },
    {
        id:26,
        list: "Ювелирные изделия"
    },
    {
        id:27,
        list: "Бренды"
    },
    {
        id:28,
        list: "Акции"
    },
    {
        id:29,
        list: "Товары для взрослых"
    },
    {
        id:30,
        list: "Цифровые товары"
    }
]
export default noneMenu