import React,{useState} from 'react';
import {Link} from "react-router-dom";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

const Footer = () => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [dropdownOpenOne, setDropdownOpenOne] = useState(false);
    const [dropdownOpenThree, setDropdownOpenThree] = useState(false);
    const [dropdownOpenFour, setDropdownOpenFour] = useState(false);
    const [dropdownOpenFive, setDropdownOpenFive] = useState(false);
    const toggle = () => setDropdownOpen(prevState => !prevState);
    const toggleOne = () => setDropdownOpenOne(prevState => !prevState);
    const toggleThree = () => setDropdownOpenThree(prevState => !prevState);
    const toggleFour = () => setDropdownOpenFour(prevState => !prevState);
    const toggleFive = () => setDropdownOpenFive(prevState => !prevState);
    return (
        <>
            <section className="footer">
                <div className="footer-main">
                    <div className="footer-items">
                        <h6>Покупателям</h6>
                        <ul>
                            <li>
                                <Link>Как сделать заказ</Link>
                                <Link>Способы оплаты</Link>
                                <Link>Доставка</Link>
                                <Link>Возврат товара</Link>
                                <Link>Возврат денежных средств</Link>
                                <Link>Правила продажи</Link>
                                <Link>Правила пользования торговой площадкой</Link>
                                <Link> Вопросы и ответы</Link>
                            </li>
                        </ul>
                    </div>
                    <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                        <DropdownToggle caret>
                            <h6 className="d-inline-block">Покупателям</h6>
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem> <Link>Как сделать заказ</Link></DropdownItem>
                            <DropdownItem> <Link>Способы оплаты</Link></DropdownItem>
                            <DropdownItem> <Link>Доставка</Link></DropdownItem>
                            <DropdownItem> <Link>Возврат товара</Link></DropdownItem>
                            <DropdownItem> <Link>Возврат денежных <br/> средств</Link></DropdownItem>
                            <DropdownItem> <Link>Правила продажи</Link></DropdownItem>
                            <DropdownItem> <Link>Правила пользования <br/> торговой площадкой</Link></DropdownItem>
                            <DropdownItem> <Link>Вопросы и ответы</Link></DropdownItem>

                        </DropdownMenu>
                    </Dropdown>
                    <Dropdown isOpen={dropdownOpenOne} toggle={toggleOne}>
                        <DropdownToggle caret>
                            <h6 className="d-inline-block">Партнерам</h6>
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem> <Link>Продавайте <br/> на Wildberries</Link></DropdownItem>
                            <DropdownItem> <Link>Перевозчикам</Link></DropdownItem>
                            <DropdownItem> <Link>Откройте <br/> пункт выдачи</Link></DropdownItem>
                            <DropdownItem> <Link>ЗОЖ и спорт</Link></DropdownItem>
                            <DropdownItem> <Link>Детям</Link></DropdownItem>
                            <DropdownItem> <Link>Трудоустройство</Link></DropdownItem>
                        </DropdownMenu>
                    </Dropdown>
                    <Dropdown isOpen={dropdownOpenThree} toggle={toggleThree}>
                        <DropdownToggle caret>
                            <h6 className="d-inline-block">Компания</h6>
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem> <Link>О нас</Link></DropdownItem>
                            <DropdownItem> <Link>Реквизиты</Link></DropdownItem>
                            <DropdownItem> <Link>Пресс-центр</Link></DropdownItem>
                            <DropdownItem> <Link>Контакты</Link></DropdownItem>
                            <DropdownItem> <Link>Вакансии</Link></DropdownItem>
                            <DropdownItem> <Link>Bug Bounty</Link></DropdownItem>
                        </DropdownMenu>
                    </Dropdown>
                    <Dropdown isOpen={dropdownOpenFour} toggle={toggleFour}>
                        <DropdownToggle caret>
                            <h6 className="d-inline-block">Мы в соцсетях</h6>
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem> <Link>Вконтакте</Link></DropdownItem>
                            <DropdownItem> <Link>Facebook</Link></DropdownItem>
                            <DropdownItem> <Link>Twitter</Link></DropdownItem>
                            <DropdownItem> <Link>Одноклассники</Link></DropdownItem>
                            <DropdownItem> <Link>YouTube</Link></DropdownItem>
                            <DropdownItem> <Link>Instagram</Link></DropdownItem>
                        </DropdownMenu>
                    </Dropdown>
                    <Dropdown isOpen={dropdownOpenFive} toggle={toggleFive}>
                        <DropdownToggle caret>
                            <h6 className="d-inline-block">Мобильные <br/> устройства</h6>
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem> <Link><img src="https://trashbox.ru/img/stores/googleplay2.png" alt=""/></Link></DropdownItem>
                            <DropdownItem> <Link><img src="https://careerist.ru/news/wp-content/uploads/2016/06/appstore.png"
                                                      alt=""/></Link></DropdownItem>
                            <DropdownItem> <Link><img src="https://tachogram.com/public/badges/appgallery/app_gallery_ru.png"
                                                      alt=""/></Link></DropdownItem>

                        </DropdownMenu>
                    </Dropdown>
                    <div>
                        <div className="footer-items">
                            <h6>Партнерам</h6>
                            <ul>
                                <li>
                                    <Link>Продавайте на Wildberries</Link>
                                    <Link>Перевозчикам</Link>
                                    <Link>Откройте пункт выдачи</Link>
                                </li>
                            </ul>
                        </div>
                        <div className="footer-items">
                            <h6>Наши проекты</h6>
                            <ul>
                                <li>
                                    <Link>ЗОЖ и спорт</Link>
                                    <Link>Детям</Link>
                                    <Link>Трудоустройство</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="footer-items">
                        <h6>Компания</h6>
                        <ul>
                            <li>
                                <Link>О нас</Link>
                                <Link>Реквизиты</Link>
                                <Link>Пресс-центр</Link>
                                <Link>Контакты</Link>
                                <Link>Вакансии</Link>
                                <Link>Bug Bounty</Link>
                            </li>
                        </ul>
                    </div>
                    <div className="footer-items">
                        <h6>Мы в соцсетях</h6>
                        <ul>
                            <li>
                                <Link>Вконтакте</Link>
                                <Link>Facebook</Link>
                                <Link>Twitter</Link>
                                <Link>Одноклассники</Link>
                                <Link>YouTube</Link>
                                <Link>Instagram</Link>
                            </li>
                        </ul>
                    </div>
                    <div className="footer-items">
                        <h6>Мобильные устройства</h6>
                        <ul>
                            <li>
                                <Link><img src="https://trashbox.ru/img/stores/googleplay2.png" alt=""/></Link>
                                <Link><img src="https://careerist.ru/news/wp-content/uploads/2016/06/appstore.png"
                                           alt=""/></Link>
                                <Link><img src="https://tachogram.com/public/badges/appgallery/app_gallery_ru.png"
                                           alt=""/></Link>
                            </li>
                        </ul>
                        <h6>Перейти на мобильную <br/> версию сайта</h6>
                    </div>
                </div>
                <div className="footer-end">
                    <p>2004-2021 © Wildberries — модный интернет-магазин одежды, обуви и аксессуаров. <br/> Все права
                        защищены. Доставка по всей России.
                    </p>
                    <Link>Проверка совместимости</Link>
                    <div>
                        <i className="fab fa-cc-visa"/>
                        <i className="fab fa-cc-mastercard"/>
                        <i className="fab fa-cc-paypal"/>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Footer;