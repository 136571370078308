import React,{useState} from 'react';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import carusel from "./carusel";
import cardImg from "./cardImg";
import productFirst from "./productFirst";
import productSecond from "./ProductSecond";
import {Link} from "react-router-dom";

const Header = () => {
    const [carusels,setCarusels]=useState(carusel)
    const [cardImgs,setCardImgs]=useState(cardImg)
    const [productFirsts,setProductFirsts]=useState(productFirst)
    const [productSeconds,setProductSeconds]=useState(productSecond)
    const responsive = {
        superLargeDesktop: {
            breakpoint: {max: 4000, min: 3000},
            items: 1
        },
        desktop: {
            breakpoint: {max: 3000, min: 1024},
            items: 1
        },
        tablet: {
            breakpoint: {max: 1024, min: 464},
            items: 1
        },
        mobile: {
            breakpoint: {max: 464, min: 0},
            items: 1
        }
    };
    return (
        <>
            <section className="header">
                <div className="container-fluid">
                    <Carousel  responsive={responsive}>
                        {carusels.map((item,index)=>{
                            const {id,img}=item
                            return(
                               <figure> <img className="w-100 carousel-img " src={img} alt=""/></figure>
                            )
                        })}
                    </Carousel>
                    <div className="row first-cards">
                        {cardImgs.map((item,index)=>{
                            const {id,img}=item
                            return(
                                <div className="col-3">
                                    <div className="card">
                                        <div className="card-body p-0">
                                            <figure><img className='w-100' src={img} alt=""/></figure>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    <div className="row second-cards">
                       <div className="col-12"> <h1>Хиты продаж</h1></div>
                        {productFirsts.map((item,index)=>{
                            const {id,img,lastPrice,price,type}=item
                            return(
                                <div className="col-2">
                                    <Link>
                                        <div className="card border-0">
                                            <div className="card-body p-0">
                                                <img src={img} alt=""/>
                                                <div className="d-flex justify-content-start align-items-center">
                                                    <h6>{lastPrice}</h6>
                                                    <span className="lastPrice">{price}</span>
                                                </div>
                                                <p>{type}</p>
                                            </div>
                                        </div>
                                    </Link>
                                    <button className="btn">Быстрый просмотр</button>
                                </div>
                            )
                        })}
                    </div>
                    <div className="row three-cards">
                        <Link>
                            <div className="col-6">
                                <div className="card">
                                    <div className="card-body">
                                        <figure><img src="https://images.wbstatic.net/bners1/938495967097.jpg" alt=""/></figure>
                                    </div>
                                </div>
                            </div>
                        </Link>
                       <Link>
                           <div className="col-6">
                               <div className="card">
                                   <div className="card-body">
                                       <figure><img src="https://images.wbstatic.net/bners1/desc_londa_professional_27_05.jpg" alt=""/></figure>
                                   </div>
                               </div>
                           </div>
                       </Link>
                    </div>
                    <div className="row second-cards">
                        {productSeconds.map((item,index)=>{
                            const {id,img,lastPrice,price,type}=item
                            return(
                                <div className="col-2">
                                    <Link>
                                        <div className="card border-0">
                                            <div className="card-body p-0">
                                                <img src={img} alt=""/>
                                                <div className="d-flex justify-content-start align-items-center">
                                                    <h6>{lastPrice}</h6>
                                                    <span className="lastPrice">{price}</span>
                                                </div>
                                                <p>{type}</p>
                                            </div>
                                        </div>
                                    </Link>
                                    <button className="btn">Быстрый просмотр</button>
                                </div>
                            )
                        })}
                    </div>
                    <div className="row three-cards">
                        <Link>
                            <div className="col-6">
                                <div className="card">
                                    <div className="card-body">
                                        <figure><img src="https://images.wbstatic.net/bners1/ecco_1709.jpg" alt=""/></figure>
                                    </div>
                                </div>
                            </div>
                        </Link>
                        <Link>
                            <div className="col-6">
                                <div className="card">
                                    <div className="card-body">
                                        <figure><img src="https://images.wbstatic.net/bners1/main_pampers_6-738394956.jpg" alt=""/></figure>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="row second-cards">
                        {productSeconds.map((item,index)=>{
                            const {id,img,lastPrice,price,type}=item
                            return(
                                <div className="col-2">
                                    <Link>
                                        <div className="card border-0">
                                            <div className="card-body p-0">
                                                <img src={img} alt=""/>
                                                <div className="d-flex justify-content-start align-items-center">
                                                    <h6>{lastPrice}</h6>
                                                    <span className="lastPrice">{price}</span>
                                                </div>
                                                <p>{type}</p>
                                            </div>
                                        </div>
                                    </Link>
                                    <button className="btn">Быстрый просмотр</button>
                                </div>
                            )
                        })}
                    </div>
                    <div className="row second-cards">
                        {productFirsts.map((item,index)=>{
                            const {id,img,lastPrice,price,type}=item
                            return(
                                <div className="col-2">
                                    <Link>
                                        <div className="card border-0">
                                            <div className="card-body p-0">
                                                <img src={img} alt=""/>
                                                <div className="d-flex justify-content-start align-items-center">
                                                    <h6>{lastPrice}</h6>
                                                    <span className="lastPrice">{price}</span>
                                                </div>
                                                <p>{type}</p>
                                            </div>
                                        </div>
                                    </Link>
                                    <button className="btn">Быстрый просмотр</button>
                                </div>
                            )
                        })}
                    </div>
                    <div className="row cards-button">
                        <div className="col-12">
                            <button className="btn">Показать еще <i className="fas fa-arrow-down"/></button>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Header;